import { WP_URL } from 'constants/config';

export const ROUTES = [
  {
    name: 'Home',
    path: `${WP_URL}/`,
  },
  { name: 'About', path: `${WP_URL}/about-us/` },
  { name: 'Teachers', path: '/' },
  { name: 'Students', path: '/students' },
  {
    name: 'Contact',
    path: `${WP_URL}/contact-us/`,
  },
  { name: 'Blog', path: `${WP_URL}/blog/` },
  { name: 'Shop', path: 'https://classroomq-swag-shop.creator-spring.com/?' },
  { name: 'Account', path: `${WP_URL}/membership-account/` },
];
