import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { useCopyToClipboard } from 'hooks/use-copy-to-clipboard';

import CopyToLinkIcon from 'assets/icons/copy-link-icon.png';

import './styles.scss';

type Props = {
  textToCopy: string;
  buttonText?: string;
  copiedActionText?: string;
};

const defaultCopiedActionText = 'Copied';

export const CopyToClipboardButton: React.FC<Props> = ({
  textToCopy,
  copiedActionText = defaultCopiedActionText,
  buttonText,
}) => {
  const [copyUrlStatus, copyUrl] = useCopyToClipboard(textToCopy, 1000);
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const handleClick = () => {
    if (showCopiedMessage || copyUrlStatus === 'copied') return;

    setShowCopiedMessage(true);
    copyUrl();
  };

  useEffect(() => {
    if (copyUrlStatus === 'copied') setShowCopiedMessage(true);
    else setShowCopiedMessage(false);
  }, [copyUrlStatus, showCopiedMessage, setShowCopiedMessage]);

  return (
    <div className="copy-to-clipboard-container">
      <span className="copy-to-clipboard-title">Share Class Link</span>
      <button className="copy-to-clipboard-btn" onClick={handleClick}>
        <img alt="copy to clipboard" src={CopyToLinkIcon} />
        {buttonText ?? ''}
        <span className={classNames('done', showCopiedMessage && 'copied')} aria-hidden="true">
          {copiedActionText}
        </span>
      </button>
    </div>
  );
};
