import React, { useEffect, useMemo, useState } from 'react';

import useSocket from 'hooks/use-socket';
import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { Buttons } from 'shared';

import 'features/student/styles.scss';
import { useQueryParams } from '../../../../hooks/use-query-params';
import Warning from '../../../../shared/warning';
import { WARNING_MESSAGES } from '../../../../constants/warning-messages';
import { SessionEnded } from '../../../../types/session-ended';
import { saveStudentName } from '../../../../utils/local-storage';

type Props = {
  onJoinedSession: (joinedSession: boolean) => void;
  onChangeClassCode: (classCode: string) => void;
  onChangeFormSubmitted: (formSubmitted: boolean) => void;
  assistanceRequestsCount: number;
};

const StudentForm: React.FC<Props> = ({ onJoinedSession, onChangeClassCode, onChangeFormSubmitted, assistanceRequestsCount }) => {
  const query = useQueryParams();
  const socket = useSocket();
  const queryClassCode = useMemo(() => query.get('classCode'), [query]);
  const [name, setName] = useState<string>('');
  const [classCode, setClassCode] = useState<string>('');
  const [sessionIsStarted, setSessionIsStarted] = useState<boolean>(true);
  const [classCodeExist, setClassCodeExist] = useState<boolean>(true);
  const [warningMessage, setWarningMessage] = useState<string>('');
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (queryClassCode) {
      setClassCode(queryClassCode);
    }
  }, [socket, queryClassCode]);

  useEffect(() => {
    queryClassCode &&
      socket?.emit(EventsMessagesEnum.ClassCodeCheck, {
        classCode: queryClassCode,
      });
  }, [socket, queryClassCode]);

  useEffect(() => {
    const classCodeCheckListener = (data: { classCodeExists: boolean; sessionExists: boolean }) => {
      if (formSubmitted && !queryClassCode && !data.classCodeExists) {
        setWarningMessage(WARNING_MESSAGES.invalidClassCode);
        setClassCodeExist(false);
        return;
      }

      if (queryClassCode && !data.classCodeExists) {
        setWarningMessage(WARNING_MESSAGES.classCodeDoesNotExists);
        setClassCodeExist(false);
        return;
      }

      if (classCode && !data.sessionExists && data.classCodeExists) {
        setWarningMessage(WARNING_MESSAGES.sessionNotStarted);
        setSessionIsStarted(false);
        return;
      }
    };
    socket?.on(EventsMessagesEnum.ClassCodeChecked, classCodeCheckListener);

    return () => {
      socket?.off(EventsMessagesEnum.ClassCodeChecked, classCodeCheckListener);
    };
  }, [socket, classCode, queryClassCode, formSubmitted]);

  useEffect(() => {
    const sessionEndedListener = (data: SessionEnded) => {
      if (queryClassCode && queryClassCode === data?.classCode) {
        setSessionIsStarted(false);
        setWarningMessage(WARNING_MESSAGES.sessionNotStarted);
        onJoinedSession(false);
      }
    };

    socket?.on(EventsMessagesEnum.SessionEnded, sessionEndedListener);
    return () => {
      socket?.off(EventsMessagesEnum.SessionEnded, sessionEndedListener);
    };
  }, [socket, queryClassCode, onJoinedSession]);

  useEffect(() => {
    const roomCreatedListener = (data: { classCode: string }) => {
      if (queryClassCode === data.classCode) {
        setWarningMessage('');
        setSessionIsStarted(true);
      }
    };

    socket?.on(EventsMessagesEnum.RoomCreated, roomCreatedListener);
    return () => {
      socket?.off(EventsMessagesEnum.RoomCreated, roomCreatedListener);
    };
  }, [socket, queryClassCode]);

  useEffect(() => {
    const studentNameAlreadyExistsListener = () => {
      setWarningMessage(WARNING_MESSAGES.duplicateStudentName);
      return;
    };

    socket?.on(EventsMessagesEnum.StudentNameAlreadyExists, studentNameAlreadyExistsListener);
    return () => {
      socket?.off(EventsMessagesEnum.StudentNameAlreadyExists, studentNameAlreadyExistsListener);
    };
  }, [socket, formSubmitted]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    onChangeFormSubmitted(true);

    if (!socket || !socket.connected) {
      return;
    }

    if (!name) {
      setWarningMessage(WARNING_MESSAGES.invalidName);
      return;
    }

    const classCodeToUpperCase = classCode.toUpperCase();

    if (!classCodeToUpperCase) {
      setWarningMessage(WARNING_MESSAGES.invalidClassCode);
      return;
    }

    saveStudentName(name);

    socket.emit(EventsMessagesEnum.ClassCodeCheck, {
      classCode: classCodeToUpperCase,
    });

    socket.emit(EventsMessagesEnum.JoinStudentToRoom, {
      name,
      classCode: classCodeToUpperCase,
    });

    setName('');
    // setClassCode('');
    onChangeClassCode(classCodeToUpperCase);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="studentsWrap">
        {sessionIsStarted && queryClassCode && (
          <div className="actionsWrap">
            <input
              type="text"
              placeholder="YOUR NAME"
              className="input"
              value={name}
              minLength={2}
              onChange={e => setName(e.target.value)}
            />
            {!classCodeExist && (
              <input
                type="text"
                placeholder="CLASS CODE"
                className="input"
                value={classCode}
                onChange={e => setClassCode(e.target.value)}
              />
            )}
            <Buttons.Common type="submit" name="OK" stylesBtn="studentButton" />
          </div>
        )}

        {!queryClassCode && (
          <div className="actionsWrap">
            <input
              type="text"
              placeholder="YOUR NAME"
              className="input"
              value={name}
              minLength={2}
              onChange={e => setName(e.target.value)}
            />

            <input
              type="text"
              placeholder="CLASS CODE"
              className="input"
              value={classCode}
              onChange={e => setClassCode(e.target.value)}
            />
            <Buttons.Common type="submit" name="OK" stylesBtn="studentButton" />
          </div>
        )}

        {warningMessage && <Warning message={warningMessage} />}
      </div>
    </form>
  );
};

export default StudentForm;
