const DEFAULT_CONFIG = {
  // apiUrl: 'https://api.room-classroomq.com',
  apiUrl: 'https://classroomq-dev.inheritxdev.in',
  wpUrl: 'http://localhost:3000',
};

export const API_URL = process.env.REACT_APP_API_URL || DEFAULT_CONFIG.apiUrl;

export const DOCUMENT_TITLE = 'ClassroomQ';

export const WP_URL = process.env.REACT_APP_WP_URL || DEFAULT_CONFIG.wpUrl;
