import React from 'react';

import './styles.scss';

type DefaultProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type Props = DefaultProps & {
  labelText: string;
  id: string;
};

export const Checkbox: React.FC<Props> = ({ labelText, id = 'checkbox', ...props }) => {
  return (
    <div className="checkbox-container">
      <input {...props} type="checkbox" id={id} />
      {labelText && <label htmlFor={id}>{labelText}</label>}
    </div>
  );
};
