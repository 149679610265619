


import { useEffect, useRef, useState } from 'react';
import { io, Socket } from 'socket.io-client';

import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { UserType } from 'enums/user-type.enum';
import { RoomParticipant } from 'types/room-participant';
import { API_URL } from 'constants/config';
import { getClassCode, getStudentId, getStudentName, getToken, saveClassCode, saveStudentId } from '../utils/local-storage';

function useSetupSocket() {
  const [socket, setSocket] = useState<Socket | null>(null);
  const reconnecting = useRef(false);

  useEffect(() => {
    if (!socket) {
      setSocket(
        io(API_URL, {
          secure: true,
          reconnection: true,
          rejectUnauthorized: false,
          closeOnBeforeunload: false,
          autoConnect: true,
          transports: ['websocket'],
        })
      );
    }
  }, [socket]);

  const reconnectWebSocket = () => {
    if (reconnecting.current) return;
    reconnecting.current = true;

    if (navigator.onLine && socket) { // Check if socket is defined

      socket.connect();

      const classCode2 = getClassCode();
      const studentId2 = getStudentId();
      if (classCode2) {

        if (!socket.connected) {
          socket.connect();
        }

        socket.emit(EventsMessagesEnum.JoinRoom, {
          classCode: getClassCode(),
          studentId: getStudentId(),
          token: getToken(),
        });
      } else if (getStudentName()) {

        socket.emit(EventsMessagesEnum.JoinStudentToRoom, {
          name: getStudentName(),
          classCode: getClassCode(),
        });
      }
      // });
      else if (!socket) {
        // If socket is null, you might want to handle this case accordingly
        console.error('Socket is null');
      }
    }
    reconnecting.current = false;
  };

  useEffect(() => {
    if (socket) {
      socket.on(EventsMessagesEnum.Disconnect, () => {

        console.info('socket connection lost', `socket id ${socket?.id}`);

        // reconnectWebSocket();
        socket.off(EventsMessagesEnum.Connect);
      });

      socket.on('reconnect', () => {
        console.log('Socket reconnected');
        // Set socket.connected to true on reconnect
        socket.connected = true;
      });


      socket.on(EventsMessagesEnum.JoinedRoom, (data: RoomParticipant) => {
        if (data?.clientId === socket.id && data?.classCode) {
          saveClassCode(data.classCode);
          if (data.userType === UserType.Student) {
            saveStudentId(data?.studentId);
          }
        }
      });

      socket.on(EventsMessagesEnum.Connect, () => {
        // eslint-disable-next-line no-console
        console.info('socket connected', `socket id ${socket.id}`);

        if (getToken()) {
          console.log("socket connect getToken method executed");
          socket.emit(EventsMessagesEnum.JoinedRoom, {
            classCode: getClassCode(),
            token: getToken(),
          });
        } else if (getStudentName()) {
          socket.emit(EventsMessagesEnum.JoinStudentToRoom, {
            name: getStudentName(),
            classCode: getClassCode(),
          });
        }
      });
    }
  }, [socket]);

  useEffect(() => {
    return () => {
      if (socket) {
        console.log("socket terminate");
        socket.close();
        setSocket(null);
      }
    };
  }, [socket]);


  useEffect(() => {
    const handleVisibilityChange = () => {

      const userAgent = navigator.userAgent;
      const isiPhone = /iPhone/.test(userAgent);
      if (isiPhone === true && socket) {
        socket.connected = false;
      }

      if ((document.visibilityState === 'visible' && !socket?.connected) || (document.visibilityState === 'visible' && isiPhone)) {
        reconnectWebSocket();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [socket, document.visibilityState]);

  // useEffect(() => {
  //   // Try to reconnect the socket when the component first mounts
  //   reconnectWebSocket();
  // }, []);

  return socket;
}

export default useSetupSocket;