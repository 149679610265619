import React, { FC } from 'react';

import './styles.scss';

type Props = {
  name: string;
  stylesWrap?: string;
  stylesBtn?: string;
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: FC<Props> = ({ size = 'sm', name, stylesWrap, stylesBtn, onClick, type = 'button', ...restProps }) => (
  <div className={stylesWrap}>
    <button {...restProps} type={type} className={'size-' + size + ' ' + stylesBtn} onClick={onClick}>
      {name}
    </button>
  </div>
);

export default Button;
