import { api } from 'api';
import { UserPayload } from 'types/user-payload';

export const getTeacherPayloadByToken = async (token: string): Promise<UserPayload> => {
  return api<UserPayload>(`/teachers/find-by-token`, {
    headers: new Headers({
      Authorization: `Bearer ${token}`,
    }),
  });
};
