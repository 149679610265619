import React, { useEffect, useMemo, useState } from 'react';

import { RoomParticipant } from 'types/room-participant';
import { AssistanceRequest } from 'types/assistance-request';

type Props = {
  assistanceRequest: AssistanceRequest;
  roomParticipants: RoomParticipant[];
  showCommentMessage: boolean;
  answerAssistanceRequest: (id: string) => void;
  ordinalPlace: number;
};

export const AssistanceRequestsItem: React.FC<Props> = ({
  assistanceRequest,
  roomParticipants,
  showCommentMessage,
  answerAssistanceRequest,
  ordinalPlace,
}) => {
  const name = useMemo(
    () => roomParticipants.find(p => p.studentId === assistanceRequest?.studentId)?.name || '',
    [roomParticipants, assistanceRequest]
  );
  const comment = useMemo(() => assistanceRequest?.comment || '', [assistanceRequest]);

  const [showComment, setShowComment] = useState(showCommentMessage);

  useEffect(() => {
    setShowComment(showCommentMessage);
  }, [showCommentMessage]);

  return (
    <>
      <li className="messages-text message-item">
        <div className="assistance-request-container">
          <p className="message-sender" onClick={() => answerAssistanceRequest(assistanceRequest?.assistanceRequestId)}>
            {`${ordinalPlace}. ${name}`}
          </p>
          {comment && (
            <img
              alt="message"
              src="https://img.icons8.com/external-flatart-icons-solid-flatarticons/64/4a90e2/external-message-chat-flatart-icons-solid-flatarticons-5.png"
              className="message-icon"
              onClick={() => setShowComment(!showComment)}
            />
          )}
        </div>

        {comment && showComment && <p className="comment">{comment}</p>}
      </li>
    </>
  );
};
