import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import Button from 'shared/buttons/Button';
import { WP_URL } from 'constants/config';

type Props = {
  show?: boolean;
};

export const KickedAlert: React.FC<Props> = ({ show = false }) => (
  <SweetAlert
    show={show}
    title="You were removed from the room"
    confirmBtnText="Ok"
    confirmBtnBsStyle="warning"
    warning
    onConfirm={() => {
      window.location.href = `${WP_URL}/students/`;
    }}
    customButtons={
      <Button
        name="Ok"
        stylesBtn="welcomeButton"
        stylesWrap="buttonWrap"
        onClick={() => {
          window.location.href = `${WP_URL}/students/`;
        }}
      />
    }
  >
    <div>
      <p>The teacher has removed you from the room.</p>
      <p>Make sure you are using an appropriate name.</p>
    </div>
  </SweetAlert>
);
