import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Classroom from './features/teacher';
import Student from './features/student';
import useSetupSocket from './hooks/use-setup-socket';
import { SocketContext } from './hooks/use-socket';
import NotFound from './features/not-found';

const App = () => {
  const socket = useSetupSocket();

  return (
    <Router>
      <SocketContext.Provider value={socket}>
        <Switch>
          <Route exact path="/" component={Classroom} />
          <Route path="/students" component={Student} />
          <Route path="" component={NotFound} />
        </Switch >
      </SocketContext.Provider >
    </Router >
  );
};

export default App;
