import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import useSocket from 'hooks/use-socket';
import { DOCUMENT_TITLE, WP_URL } from 'constants/config';
import { EventsMessagesEnum } from 'enums/events-messages.enum';
import { UserType } from 'enums/user-type.enum';
import { RoomParticipant } from 'types/room-participant';
import { AssistanceRequest } from 'types/assistance-request';
import { Alerts } from 'shared';
import { getUnresolvedAssistanceRequests } from 'utils/get-unresolved-assistance-requests';
import { playNotificationSound } from 'features/teacher/utils/play-notification-sound';
import { setNotificationsInterval } from 'features/teacher/utils/set-notifications-interval';
import { membershipTypePaid } from 'features/teacher/utils/membership-type';
import { getClassCode, getToken } from 'utils/local-storage';
import { ReactComponent as WarningImg } from 'assets/icons/warning.svg';
import { userContext } from 'context/user-context';
import { QUEUE_LIMIT } from 'constants/room';
import { MembershipEnum } from 'enums/membership.enum';
import { AssistanceRequestsList } from './assistance-requests-list';
import { RoomParticipants } from './room-participants';
import { Toolbar } from './toolbar';
import './styles.scss';
import { SessionEnded } from '../../../../types/session-ended';

type Props = {
  onSessionEnd: () => void;
};

const TeachersClassroom: React.FC<Props> = ({ onSessionEnd }) => {
  const { state: user } = useContext(userContext);
  const membership = useMemo(() => user && user?.membership, [user]);
  const [studentsList, setStudentsList] = useState<boolean>(true);
  const [playSounds, setPlaySounds] = useState<boolean>(true);
  const [showCommentsMessages, setShowCommentsMessages] = useState<boolean>(true);
  const [showPopupUpsell, setShowPopupUpsell] = useState<boolean>(true);
  const [showUpsell, setShowUpsell] = useState<boolean>(false);
  const [kickPopupShow, setKickPopupShow] = useState<boolean>(false);
  const [nameToKick, setNameToKick] = useState<string>('');
  const [roomParticipants, setRoomParticipants] = useState<RoomParticipant[]>([]);
  const [assistanceRequests, setAssistanceRequests] = useState<AssistanceRequest[]>([]);
  const [showEndSessionPopup, setShowEndSessionPopup] = useState(false);
  const [unResolvedRequests, setUnResolvedRequests] = useState<AssistanceRequest[]>([]);
  const [notificationInterval, setNotificationInterval] = useState<NodeJS.Timeout | null>(null);
  const classCode = useMemo(() => getClassCode(), []);
  const socket = useSocket();

  const kickUser = (name: string) => {
    return name;
  };

  const answerAssistanceRequest = (id: string) => {
    if (!socket || !socket.connected || !user || Object.keys(user)?.length < 3) return;

    socket?.emit(EventsMessagesEnum.AnswerAssistanceRequest, {
      id,
      classCode,
      teacherId: user.id,
      token: getToken(),
    });
    console.log("assistanceRequests Before***", assistanceRequests);
    setAssistanceRequests(assistanceRequests.map(a => (a.assistanceRequestId === id ? { ...a, isResolved: true } : a)));
    console.log("assistanceRequests After***", assistanceRequests);
  };

  const queueLimit = useCallback(
    (unResolvedRequestsCount: number) => {
      return membership !== MembershipEnum.Pro && unResolvedRequestsCount >= QUEUE_LIMIT;
    },
    [membership]
  );

  const clearNotificationInterval = useCallback(() => {
    if (!notificationInterval) {
      return;
    }

    document.title = DOCUMENT_TITLE;
    clearInterval(notificationInterval);
    setNotificationInterval(null);
  }, [notificationInterval, setNotificationInterval]);

  useEffect(() => {
    if (!socket || !classCode) {
      return;
    }

    socket.emit(EventsMessagesEnum.QueueStatus, {
      classCode,
      isFull: queueLimit(unResolvedRequests.length),
      token: getToken(),
    });
  }, [roomParticipants, unResolvedRequests, classCode, socket, queueLimit]);

  useEffect(() => {
    if (!socket || !classCode) {
      return;
    }

    socket.emit(EventsMessagesEnum.JoinRoom, {
      classCode,
      token: getToken(),
    });
  }, [classCode, socket]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const joinRoomListener = (roomParticipant: RoomParticipant) => {
      console.log("teacherClassDetails", roomParticipant);

      if (roomParticipant.userType === UserType.Teacher) {
        return;
      }

      const isParticipantAlreadyJoined = !!roomParticipants.find(r => r.clientId === roomParticipant.clientId);
      !isParticipantAlreadyJoined && setRoomParticipants([...roomParticipants, roomParticipant]);
    };

    socket.on(EventsMessagesEnum.JoinedRoom, joinRoomListener);
    return () => {
      socket.off(EventsMessagesEnum.JoinedRoom, joinRoomListener);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const leftRoomListener = (roomParticipant: { clientId: string; userId?: string }) => {
      console.log("teacherClassDetails leftRoom", roomParticipant);
      // console.log("assistanceRequests Before", assistanceRequests);
      // const answerAssistanceRequests = assistanceRequests.filter(a => a.studentId !== roomParticipant?.userId);
      // console.log("assistanceRequests After", answerAssistanceRequests);
      // setAssistanceRequests(answerAssistanceRequests);
      setUnResolvedRequests(getUnresolvedAssistanceRequests(assistanceRequests));
      setRoomParticipants(roomParticipants.filter(r => r.studentId !== roomParticipant?.userId));
      console.log("assistanceRequests final", assistanceRequests);
    };

    socket.on(EventsMessagesEnum.LeftRoom, leftRoomListener);
    return () => {
      socket.off(EventsMessagesEnum.LeftRoom, leftRoomListener);
    };
  }, [socket, assistanceRequests, roomParticipants]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const assistanceRequestsListener = (payload: { assistanceRequests: AssistanceRequest[] }) => {
      // console.log("payload", payload);

      setAssistanceRequests(payload?.assistanceRequests || []);
      const unResolvedAssistanceRequests = getUnresolvedAssistanceRequests(payload?.assistanceRequests);
      setUnResolvedRequests(unResolvedAssistanceRequests);

      const isPageActive = !document.hidden;

      if (unResolvedAssistanceRequests?.length > unResolvedRequests?.length) {
        if (!notificationInterval) {
          const interval = setNotificationsInterval();
          setNotificationInterval(interval);
        }
        playSounds && playNotificationSound();
      } else if (!isPageActive && notificationInterval && unResolvedAssistanceRequests?.length === 0) {
        clearNotificationInterval();
      }
    };

    socket.on(EventsMessagesEnum.AssistanceRequests, assistanceRequestsListener);
    return () => {
      socket.off(EventsMessagesEnum.AssistanceRequests, assistanceRequestsListener);
    };
  }, [socket, assistanceRequests, unResolvedRequests, clearNotificationInterval, notificationInterval, playSounds]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const roomParticipantsListener = (participants: RoomParticipant[]) => {
      setRoomParticipants(participants || []);
    };

    socket.on(EventsMessagesEnum.RoomParticipants, roomParticipantsListener);
    return () => {
      socket.off(EventsMessagesEnum.RoomParticipants, roomParticipantsListener);
    };
  }, [socket]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const sessionEndedListener = (data: SessionEnded) => {
      if (!data || classCode !== data.classCode) {
        return;
      }

      setAssistanceRequests([]);
      setRoomParticipants([]);
      onSessionEnd();
    };

    socket.on(EventsMessagesEnum.SessionEnded, sessionEndedListener);

    return () => {
      socket.off(EventsMessagesEnum.SessionEnded, sessionEndedListener);
    };
  }, [socket, classCode, onSessionEnd]);

  useEffect(() => {
    window.addEventListener('focus', clearNotificationInterval);

    if (document.hasFocus()) {
      clearNotificationInterval();
    }

    return () => {
      window.removeEventListener('focus', clearNotificationInterval);
    };
  }, [clearNotificationInterval, notificationInterval]);

  return (
    <div className="content">
      <Toolbar
        onPlaySoundsChange={() => setPlaySounds(!playSounds)}
        onShowCommentsChange={() => setShowCommentsMessages(!showCommentsMessages)}
        onEndSession={() => setShowEndSessionPopup(true)}
        unResolvedRequests={unResolvedRequests}
      />

      <div className="classroom-students">
        <div
          className="messages"
          style={membershipTypePaid() && roomParticipants && studentsList ? { width: '60%' } : { width: '100%' }}
        >
          {membershipTypePaid() && !!roomParticipants.length && (
            <div className="toggle-participants" onClick={() => setStudentsList(!studentsList)}>
              <img
                alt="arrow"
                src="https://img.icons8.com/material-rounded/50/000000/more-than.png"
                className={classNames('arrow-icon', !studentsList && 'arrow-icon-rotate')}
              />
            </div>
          )}
          <AssistanceRequestsList
            assistanceRequests={unResolvedRequests}
            roomParticipants={roomParticipants}
            showCommentsMessages={showCommentsMessages}
            answerAssistanceRequest={answerAssistanceRequest}
          />
        </div>
        {membershipTypePaid() && !!roomParticipants.length && (
          <div
            style={studentsList ? { display: 'flex' } : { display: 'none' }}
            className="session-participants-container"
          >
            <RoomParticipants assistanceRequests={assistanceRequests} roomParticipants={roomParticipants} />
          </div>
        )}
      </div>

      {queueLimit(unResolvedRequests.length) && (
        <div className="queue-limit-container">
          <div className="warning-img-container">
            <WarningImg />
          </div>
          <div>
            The queue is currently full. Pro members have unlimited names that can be added to the queue! {'\n'}
            <a
              target="_blank"
              rel="noreferrer"
              href={`${WP_URL}/membership-account/membership-checkout/?level=2`}
              className="pro-membership-link"
            >
              Click here
            </a>{' '}
            to become a pro member.
          </div>
        </div>
      )}

      <Alerts.QueueLimitReached
        show={!showPopupUpsell && showUpsell}
        onConfirm={() => {
          setShowUpsell(false);
          setShowPopupUpsell(true);
        }}
        onCancel={() => {
          setShowUpsell(false);
          setShowPopupUpsell(true);
        }}
      />
      <Alerts.Kick
        nameToKick={nameToKick}
        show={kickPopupShow}
        onConfirm={() => {
          kickUser(nameToKick);
          setKickPopupShow(false);
          setNameToKick('');
        }}
        onCancel={() => {
          setKickPopupShow(false);
          setNameToKick('');
        }}
      />
      <Alerts.EndSession
        membershipType={membership as unknown as MembershipEnum}
        show={showEndSessionPopup}
        onConfirm={() => {
          onSessionEnd();
          setShowEndSessionPopup(false);
        }}
      />
    </div>
  );
};
export default TeachersClassroom;
