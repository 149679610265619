import { API_URL } from 'constants/config';

const handleError = async (res: Response) => {
  const responseText = await res.text();
  const error = await JSON.parse(responseText);
  throw new Error(error?.message || error?.error || res?.statusText || 'Unknown error');
};

export const api = async <T>(url: string, options?: RequestInit): Promise<T> => {
  const response = await fetch(`${API_URL}${url}`, options);
  if (!response.ok) await handleError(response);

  return (await response.json()) as Promise<T>;
};
