import React from 'react';
import classNames from 'classnames';

import { WP_URL } from 'constants/config';

import './footer.scss';

const Footer = () => {
  return (
    <div className="footerContainer">
      <div className="footerWrap">
        <p className="copyright">© 2021 · ClassroomQ | </p>
        <a className={classNames('footerLink', 'mobile')} href={`${WP_URL}/privacy-policy/`}>
          Privacy Policy
        </a>
        <span className="between">{' - '}</span>
        <a className="footerLink" href={`${WP_URL}/terms-of-service/`}>
          Terms Of Service
        </a>
        <span className="between">{` - `}</span>
        <a className="footerLink" href={`${WP_URL}/classroomq-refund-policy/`}>
          Refund Policy
        </a>
      </div>
    </div>
  );
};

export default Footer;
