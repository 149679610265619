import React from 'react';
import { slide as Menu } from 'react-burger-menu';

import { ROUTES } from 'constants/routes';
import { WP_URL } from '../../constants/config';
import { getToken } from '../../utils/local-storage';

type Props = {
  onLogout: () => void;
};

const SideBar: React.FC<Props> = ({ onLogout }) => {
  return (
    <Menu right>
      {ROUTES.map((route, index) => (
        <div key={`${route.name}-${index}`} className="item" style={{ color: '000' }}>
          <a href={route.path}>{route.name}</a>
        </div>
      ))}
      {getToken() && (
        <div className="item" style={{ color: '000' }} onClick={onLogout}>
          <a href={`${WP_URL}/wp-login.php?action=logout`}>Log Out</a>
        </div>
      )}
    </Menu>
  );
};

export default SideBar;
