import { AssistanceRequest } from 'types/assistance-request';
import { RoomParticipant } from 'types/room-participant';

export const addRequestsCountToParticipants = (participants: RoomParticipant[], requests: AssistanceRequest[]) => {
  if (!participants?.length) return [];

  console.log(" participants Before", participants)

  console.log(" ***** requests **** ", requests);
  // requests = requests.filter(a => !a?.isResolved);
  // console.log(" ***** requests after**** ", requests);

  participants = participants.filter(obj1 => requests.some(obj2 => obj2.studentId === obj1.studentId));

  console.log(" participants After", participants)

  return participants?.map(participant => ({
    requestsCount: requests?.filter(r => r?.studentId === participant?.studentId)?.length || 0,
    ...participant,
  }));
};