import React from 'react';
import { Footer, NavBar } from '../../shared';
import './styles.scss';
import { WP_URL } from '../../constants/config';

const NotFound = () => {
  return (
    <>
      <div className="container">
        <NavBar />
        <div className="notFoundWrapper">
          <div className="notFoundTitle">404 PAGE NOT FOUND</div>
          <div className="notFoundDescription">Sorry, but we couldn't find the content you were looking for.</div>
          <div className="notFoundBackHomeButton">
            <button type="button" className="size-sm button" onClick={() => (window.location.href = WP_URL)}>
              Back to our Home
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NotFound;
